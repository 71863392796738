import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import "./Clients.css";
import GenerativeAiSlider from "./slides/Slider";
import bing from "../../../assets/Images/slider/bing.png";
import chatgpt from "../../../assets/Images/slider/chatgpt.png";
import Gemini from "../../../assets/Images/slider/Gemini.png";
import OpenAI from "../../../assets/Images/slider/OpenAI.png";
import vercel from "../../../assets/Images/slider/vercel.png";
import claudeai2 from "../../../assets/Images/slider/claudeai2.png";
import freshcaht from "../../../assets/Images/slider/freshcaht.png";
import AIChatButton from "../../components/AIChatButton/AIChatButton";

const AdvanceFeatures = ({ showText = true }) => {
  const slides = [
    {
      image: bing,
    },
    {
      image: chatgpt,
    },
    {
      image: Gemini,
    },
    {
      image: OpenAI,
    },
    {
      image: vercel,
    },
    {
      image: claudeai2,
    },
    {
      image: freshcaht,
    },
  ];

  return (
    <div>
      <div className="spark-clients-slider">
        <section id="spark-clients" className="section-bg">
          <div className="container ">
            {
              <div
                className={`${
                  showText
                    ? "spark-advance-features-header"
                    : "spark-advance-features-header2"
                }`}
              >
                <h1>Generative AI Cloud Services</h1>
                <p>
                  We are expert in developing solutions with latest generative
                  AI technologies
                </p>
              </div>
            }

            <div>
              <GenerativeAiSlider OurSlides={slides} aiSliderImages={true} />
            </div>

            <div className="mt-5 d-flex justify-content-center">
              <AIChatButton />
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default AdvanceFeatures;
