import React from "react";
import "./AIChatButton.css";
import chatbotImage from "../../../assets/gifs/bot.gif";

const AIChatButton = () => {
  const handleClick = () => {
    window.open("https://ask.sparkixtech.com", "_blank");
  };
  return (
    <button className="ai-chat-button" onClick={handleClick}>
      <img
        src={chatbotImage}
        alt="AI Assistant"
        className="ai-chat-button-img"
      />
      <span>Talk with our AI Expert</span>
    </button>
  );
};

export default AIChatButton;
