import React, { useEffect, useState } from "react";
import Select from "react-select";
import {
  faArrowRight,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./ScheduleCall.css";
// import img1 from "../../../assets/Images/google.png";
// import img2 from "../../../assets/Images/rolls-royce-white.png";
// import img3 from "../../../assets/Images/netgear.png";
// import img4 from "../../../assets/Images/ernst-young.png";
// import img5 from "../../../assets/Images/siriusxm.png";
// import img6 from "../../../assets/Images/hp.png";
import { emailRegex } from "../../../utils/Regex";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { useStateContext } from "../../../context/AppContext";
import AIChatButton from "../../components/AIChatButton/AIChatButton";

export default function ScheduleCall() {
  const { dark } = useStateContext();
  const [isLoading, setisLoading] = useState(false);

  const [inpData, setinpData] = useState({
    yourName: "",
    workEmail: "",
    yourService: [],
    assistance: "",
  });

  const [errorLabels, setErrorLabels] = useState({
    yourName: false,
    workEmail: false,
    yourService: false,
    assistance: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setinpData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === "yourName" && !value) {
      return setErrorLabels((prevData) => ({ ...prevData, [name]: true }));
    } else {
      setErrorLabels((prevData) => ({ ...prevData, [name]: false }));
    }

    if (name === "workEmail" && (!value || !emailRegex.test(value))) {
      return setErrorLabels((prevData) => ({ ...prevData, [name]: true }));
    } else {
      setErrorLabels((prevData) => ({ ...prevData, [name]: false }));
    }

    if (name === "assistance" && !value) {
      return setErrorLabels((prevData) => ({ ...prevData, [name]: true }));
    } else {
      setErrorLabels((prevData) => ({ ...prevData, [name]: false }));
    }
  };

  const handleChangeSelect = (e) => {
    setinpData((prevData) => ({
      ...prevData,
      yourService: e,
    }));

    if (e.length === 0) {
      return setErrorLabels((prevData) => ({
        ...prevData,
        yourService: true,
      }));
    } else {
      setErrorLabels((prevData) => ({ ...prevData, yourService: false }));
    }
  };

  const data = [
    {
      label: "AI Chatbot Development and SaaS Solutions",
      value: "AI Chatbot Development and SaaS Solutions",
    },
    { label: "Website Development", value: "Website Development" },
    {
      label: "Mobile App Development (Android and iOS- Hybrid Apps)",
      value: "Mobile App Development (Android and iOS- Hybrid Apps)",
    },
    {
      label: "Machine Learning and deep learning Solutions",
      value: "Machine Learning and deep learning Solutions",
    },
    {
      label: "Computer vision and NLP solutions",
      value: "Computer vision and NLP solutions",
    },
    {
      label: "QA Testing & Automation",
      value: "QA Testing & Automation",
    },
    {
      label: "Logo Designing",
      value: "Logo Designing",
    },
    {
      label: "UI/UX Designing",
      value: "UI/UX Designing",
    },
  ];

  const handleSubmit = () => {
    if (inpData.yourName.trim() === "") {
      return setErrorLabels((prevLabels) => ({
        ...prevLabels,
        yourName: true,
      }));
    }
    if (inpData.workEmail.trim() === "") {
      return setErrorLabels((prevLabels) => ({
        ...prevLabels,
        workEmail: true,
      }));
    }
    if (inpData.yourService.length === 0) {
      return setErrorLabels((prevLabels) => ({
        ...prevLabels,
        yourService: true,
      }));
    }
    if (inpData.assistance.trim() === "") {
      return setErrorLabels((prevLabels) => ({
        ...prevLabels,
        assistance: true,
      }));
    }

    if (Object.values(errorLabels).some((error) => error)) return;

    const serviceLabels = inpData.yourService.map((service) => service.label);
    const servicesString = serviceLabels.join(", ");

    setisLoading(true);

    const templateParams = {
      name: inpData.yourName,
      email: inpData.workEmail,
      services: servicesString,
      message: inpData.assistance,
    };

    emailjs
      .send(
        "service_w7vzlcd",
        "template_zjex6ha",
        templateParams,
        "Vz0IY_UBK7Gw-O5n9"
      )
      .then(
        (result) => {
          toast.success("Email Sent Successfully");
          setinpData({
            yourName: "",
            workEmail: "",
            yourService: [],
            assistance: "",
          });
          setisLoading(false);
        },
        (error) => {
          console.log(error.text);
          setisLoading(false);
        }
      );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Navbar />
      <div className="container-fluid mt-5">
        <div className="row">
          <div className="col-lg-12 col-md-12 p-0">
            <div className="schedulecall-left">
              <div className="schedulecall-left-wrap">
                <p style={{ color: "black" }}>
                  Thanks for your interest. Enter your name and details so we
                  can connect you with one of our team.
                </p>

                <div>
                  <div className="sc-input-wrap">
                    <p style={{ color: "black" }}>Your name</p>
                    <input
                      className={`form-control ${
                        errorLabels.yourName && "active"
                      }`}
                      name="yourName"
                      value={inpData.yourName}
                      onChange={handleChange}
                      placeholder="Your name"
                    />
                    <label
                      style={{ color: !errorLabels.yourName && "transparent" }}
                    >
                      Your name is required.
                    </label>
                    {errorLabels.yourName && (
                      <FontAwesomeIcon
                        className="sc-input-icon"
                        icon={faTriangleExclamation}
                      />
                    )}
                  </div>

                  <div className="sc-input-wrap">
                    <p style={{ color: "black" }}>Your work email</p>
                    <input
                      className={`form-control ${
                        errorLabels.workEmail && "active"
                      }`}
                      name="workEmail"
                      value={inpData.workEmail}
                      onChange={handleChange}
                      placeholder="Your work email"
                    />
                    <label
                      style={{ color: !errorLabels.workEmail && "transparent" }}
                    >
                      Your email address is required.
                    </label>
                    {errorLabels.workEmail && (
                      <FontAwesomeIcon
                        className="sc-input-icon"
                        icon={faTriangleExclamation}
                      />
                    )}
                  </div>

                  <div className="sc-input-wrap">
                    <p style={{ color: "black" }}>Select your service</p>
                    <Select
                      onChange={handleChangeSelect}
                      options={data}
                      value={inpData.yourService}
                      isMulti
                      className={`${errorLabels.yourService && "active2"}`}
                    />
                    <label
                      style={{
                        color: !errorLabels.yourService && "transparent",
                      }}
                    >
                      Field is required
                    </label>
                  </div>

                  <div className="sc-input-wrap">
                    <p style={{ color: "black" }}>How can we help?</p>
                    <textarea
                      className={`form-control ${
                        errorLabels.assistance && "active"
                      }`}
                      name="assistance"
                      value={inpData.assistance}
                      onChange={handleChange}
                      placeholder="Tell us how we can help"
                    />
                    <label
                      style={{
                        color: !errorLabels.assistance && "transparent",
                      }}
                    >
                      Field is required
                    </label>
                    {errorLabels.assistance && (
                      <FontAwesomeIcon
                        className="sc-input-icon"
                        icon={faTriangleExclamation}
                      />
                    )}
                  </div>

                  <div className="sc-input-wrap">
                    <div className="d-flex justify-content-end">
                      <button
                        style={{ backgroundColor: dark }}
                        onClick={handleSubmit}
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          "Loading ..."
                        ) : (
                          <>
                            Get started <FontAwesomeIcon icon={faArrowRight} />
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="col-lg-4 col-md-12 p-0">
            <div
              className="schedulecall-right"
              style={{ backgroundColor: light }}
            >
              <div>
                <p>Amazing clients who trust us</p>
                <figure>
                  <img src={img1} />
                </figure>
                <figure>
                  <img src={img2} />
                </figure>
                <figure>
                  <img src={img3} />
                </figure>
                <figure>
                  <img src={img4} />
                </figure>
                <figure>
                  <img src={img5} />
                </figure>
                <figure>
                  <img src={img6} />
                </figure>
              </div>
            </div>
          </div> */}
        </div>
        <div className="mt-5 d-flex justify-content-center">
          <AIChatButton />
        </div>
      </div>
      <Footer />
    </>
  );
}
