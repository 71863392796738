import React, { useEffect } from "react";
import Navbar from "../components/Navbar/Navbar";
import HeroSection from "./aiboot/Herosection";
import egentboot from "../../assets/Images/egentboot.png";
import Giogandaai from "../../assets/Images/Giogandaai.png";
import { Col, Container, Row } from "react-bootstrap";
import "./aiboot.css";
// import process from "../../assets/Images/process.jpg";
import Footer from "../components/Footer/Footer";
import OurClients from "../Frontend/Ourclients/Clients";
import chatwithus from "../../assets/Images/chatwithus.png";
import saasbased from "../../assets/Images/saasbased.png";
// import FAQ from "./FAQs/Faq";
import SparkAccordian from "./FAQs/Faq";
import AdvanceFeatures from "../Frontend/Ourclients/AdvanceFeatures";
import AIChatButton from "../components/AIChatButton/AIChatButton";
const AiChatbot = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Navbar />

      <HeroSection />

      <div className="mt-5 mb-5 d-flex justify-content-center">
        <AIChatButton />
      </div>

      <div
        style={{
          marginTop: "15px",
        }}
      >
        <div className=" py-6">
          <Container>
            <Row className="align-items-center">
              <Col className="spark-powerd-items-aiboot" md={4}>
                <div className="  fadeInUp" data-wow-delay="0.1s">
                  <div className="feature-item bg-light rounded text-center p-5 with-shadow">
                    <div
                      style={{
                        maxHeight: "100px",
                        maxWidth: "100px",
                      }}
                    >
                      <i
                        className="fa-4x  bi-lightbulb  mb-5"
                        style={{
                          color: "#284F8D",
                        }}
                      ></i>
                    </div>

                    <h5 className="mb-3">AI-Powered Chatbot</h5>
                    <p className="m-0">
                      Empower customer engagement with our seamless AI chatbot,
                      delivering personalized experiences and instant support
                      for enhanced user satisfaction and conversions.
                    </p>
                  </div>
                </div>
              </Col>

              <Col className="spark-powerd-items-aiboot" md={4}>
                <div className="wow fadeInUp" data-wow-delay="0.3s">
                  <div className="feature-item bg-light rounded text-center p-5 with-shadow">
                    <i
                      className="fa fa-4x  bi-layers  mb-4"
                      style={{
                        color: "#284F8D",
                      }}
                    ></i>

                    <h5 className="mb-3">AI SaaS-Based Applications</h5>
                    <p className="m-0">
                      Experience the power of our AI-based SaaS applications,
                      designed to streamline your business operations, boost
                      productivity, and adapt seamlessly to your evolving needs.
                    </p>
                  </div>
                </div>
              </Col>

              <Col className="spark-powerd-items-aiboot" md={4}>
                <div className="wow fadeInUp" data-wow-delay="0.3s">
                  <div className="feature-item bg-light rounded text-center p-5 with-shadow">
                    <i
                      className="fa fa-4x bi-gear  mb-4"
                      style={{
                        color: "#284F8D",
                      }}
                    ></i>

                    <h5 className="mb-3">Custom Solutions for Your Business</h5>
                    <p className="m-0">
                      Tailored solutions to address your business needs,
                      developed by our expert team for growth and success.
                      <br />
                      <br />
                      <br />
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div
          className="py-12"
          id="about"
          style={{
            marginTop: "15px",
          }}
        >
          <Container>
            <Row
              style={{
                marginTop: "45px",
              }}
            >
              <Col md={6}>
                <div className="wow fadeInUp" data-wow-delay="0.1s">
                  <h1 className="spark-transform-business-heading mb-4">
                    Transform Your Business with Custom AI Solutions
                  </h1>
                  <p className="mb-4">
                    Unlock the full potential of your business with our bespoke
                    AI-powered software development services. Specializing in
                    web, mobile, chatbot, and desktop applications, we craft
                    tailored solutions designed to enhance productivity,
                    streamline operations, and foster collaboration within your
                    organization. Our expert team ensures seamless integration
                    with your existing systems, minimizing disruption and
                    maximizing efficiency from day one.
                  </p>
                  <div className="d-flex mb-4">
                    <div
                      className="flex-shrink-0 btn-square rounded-circle  text-white"
                      style={{
                        backgroundColor: "var(--primary-color)",
                      }}
                    >
                      <i className="fa fa-check"></i>
                    </div>
                    <div className="ms-4">
                      <h5>Tailored Development Process</h5>
                      <p className="mb-0">
                        Our approach is centered around understanding your
                        unique business needs and delivering custom AI solutions
                        that integrate perfectly into your existing operations.
                        This enables you to benefit from enhanced
                        functionalities without the complexities typically
                        associated with new software implementation.
                      </p>
                    </div>
                  </div>
                  <div className="d-flex mb-4">
                    <div
                      className="flex-shrink-0 btn-square rounded-circle  text-white"
                      style={{
                        backgroundColor: "var(--primary-color)",
                      }}
                    >
                      <i className="fa fa-check"></i>
                    </div>
                    <div className="ms-4">
                      <h5>Continuous Support and Maintenance</h5>
                      <p className="mb-0">
                        We provide round-the-clock support and maintenance,
                        ensuring that any issues are addressed swiftly and your
                        custom solutions continue to perform optimally, thereby
                        guaranteeing uninterrupted business operations and
                        enhanced user satisfaction.
                      </p>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div
                  className=""
                  style={{
                    textAlign: "center",
                    paddingTop: "50px",
                  }}
                >
                  <img
                    className="spark-manage-scrool-animations"
                    style={{
                      width: "70%",
                      height: "90%",
                    }}
                    data-wow-delay="0.5s"
                    src={egentboot}
                    alt=""
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div class="my-6 py-5" id="overview">
          <div class="container">
            <div className="align-items-center text-center">
              <h1
                class="mb-3"
                style={{
                  color: "var(--primary-color)",
                  fontSize: "40px",
                  fontWeight: "700",
                }}
              >
                Case Studies
              </h1>
            </div>

            <div class="row  py-5 align-items-center">
              <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                {/* <img class="img-fluid rounded" src={egentboot} /> */}
                <div>
                  <iframe
                    className="sparkixtech-website-iframe-Link"
                    width="100%"
                    height="350"
                    src="https://www.youtube.com/embed/z0f0rgrCFxg"
                    title="AI Customer Support Automation Software - Sparkix Technologies"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowFullScreen={true}
                    style={{
                      borderRadius: "5px",
                    }}
                  ></iframe>
                </div>
              </div>
              <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                <div class="d-flex align-items-center mb-4">
                  <h1 class="mb-0">01</h1>
                  <span
                    class="mx-2"
                    style={{
                      width: "30px",
                      height: "2px",
                      backgroundColor: "var(--primary-color)",
                    }}
                  ></span>
                  <h5 class="mb-0">AI Customer Support Automation Software</h5>
                </div>
                <p class="mb-0">
                  Are you getting a lot of messages from your customers on
                  Instagram & Facebook Pages? Not sure how the AI can
                  automatically send replies to customers? How can AI collect
                  leads from the conversation and boost sales? We have a
                  solution for you!
                </p>
                <p>
                  {" "}
                  We developed an AI Customer Support Software for one of our
                  best clients and automated their customer support completely.
                  We can customize it for your business, connecting your
                  Facebook & Instagram pages. Our AI chatbot handles inquiries,
                  allowing your team to focus on what they do best.
                </p>
                <h6>Key Features:</h6>
                <p class="mb-0">
                  <i
                    class="fa fa-check-circle  me-3"
                    style={{
                      color: "var(--primary-color)",
                    }}
                  ></i>
                  <span
                    style={
                      {
                        // fontWeight: "500",
                      }
                    }
                  >
                    Customizable AI Chatbot
                  </span>
                  {/* Train the bot with your data, making it truly yours. */}
                </p>
                <p class="mb-0">
                  <i
                    class="fa fa-check-circle  me-3"
                    style={{
                      color: "var(--primary-color)",
                    }}
                  ></i>
                  <span
                    style={
                      {
                        // fontWeight: "500",
                      }
                    }
                  >
                    Leads Management Dashboard
                  </span>
                  {/* Efficiently track customer interactions and follow up. */}
                </p>
                <p class="mb-0">
                  <i
                    class="fa fa-check-circle  me-3"
                    style={{
                      color: "var(--primary-color)",
                    }}
                  ></i>
                  <span
                    style={
                      {
                        // fontWeight: "500",
                      }
                    }
                  >
                    Detailed AI Analytics
                  </span>{" "}
                  {/* Gain insights into customer interactions for each connected
                  page. */}
                </p>
                <p class="mb-0">
                  <i
                    class="fa fa-check-circle  me-3"
                    style={{
                      color: "var(--primary-color)",
                    }}
                  ></i>
                  <span
                    style={
                      {
                        // fontWeight: "500",
                      }
                    }
                  >
                    Manage Customer Support Teams
                  </span>{" "}
                  {/* Easily manage your bot trainers and create role based user for
                  customer support team. */}
                </p>
              </div>
            </div>
            <div class="row  py-5 align-items-center flex-column-reverse flex-lg-row">
              <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                <div class="d-flex align-items-center mb-4">
                  <h1 class="mb-0">02</h1>
                  <span
                    class="mx-2"
                    style={{
                      width: "30px",
                      height: "2px",
                      backgroundColor: "var(--primary-color)",
                    }}
                  ></span>
                  <h5 class="mb-0">AI Language Tutor - Voice Bot</h5>
                </div>
                <p class="mb-4">
                  We're excited to introduce our SaaS product at{" "}
                  <strong>Sparkix Technologies</strong>: a voice-to-voice AI
                  Language Tutor. This tool is designed to boost your speaking
                  abilities in any language. Whether you're at the grocery
                  store, airport, traveling, at work, or chatting about dating
                  and family, our tutor adapts to any topic you choose!
                </p>
                <h6>Key Features:</h6>
                <p class="mb-0">
                  <i
                    class="fa fa-check-circle  me-3"
                    style={{
                      color: "var(--primary-color)",
                    }}
                  ></i>
                  Voice-to-voice communication
                </p>
                <p class="mb-0">
                  <i
                    class="fa fa-check-circle me-3"
                    style={{
                      color: "var(--primary-color)",
                    }}
                  ></i>
                  Talk & Listen in your favorite language
                </p>
                <p class="mb-0">
                  <i
                    class="fa fa-check-circle  me-3"
                    style={{
                      color: "var(--primary-color)",
                    }}
                  ></i>
                  Learn any skill, language and more by directly talking with AI
                  voice bot
                </p>
                <p class="mb-0">
                  <i
                    class="fa fa-check-circle me-3"
                    style={{
                      color: "var(--primary-color)",
                    }}
                  ></i>
                  Enhance Speaking
                </p>
                <p class="mb-0">
                  <i
                    class="fa fa-check-circle me-3"
                    style={{
                      color: "var(--primary-color)",
                    }}
                  ></i>
                  Track your API cost in Admin Panel
                </p>
              </div>
              <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                {/* <img class="img-fluid rounded" src={egentboot} /> */}
                <div>
                  <iframe
                    className="sparkixtech-website-iframe-Link"
                    width="100%"
                    height="350"
                    src="https://www.youtube.com/embed/EIjnyVEclKM"
                    title="AI Language Tutor (Voice Bot) - Sparkix Technologies #aivoicebot"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowFullScreen={true}
                    style={{
                      borderRadius: "5px",
                    }}
                  ></iframe>
                </div>
              </div>
            </div>
            <div class="row  py-5 align-items-center">
              <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                <img
                  class="img-fluid rounded"
                  src={Giogandaai}
                  className="sparkixtech-website-image-Link"
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                  alt=""
                />
              </div>
              <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                <div class="d-flex align-items-center mb-4">
                  <h1 class="mb-0">03</h1>
                  <span
                    class="mx-2"
                    style={{
                      width: "30px",
                      height: "2px",
                      backgroundColor: "var(--primary-color)",
                    }}
                  ></span>
                  <h5 class="mb-0">
                    Develop AI-Powered Inbound/Outbound Sales Calling Solutions
                  </h5>
                </div>
                <p class="mb-4">
                  Our AI-powered sales calling system revolutionizes how
                  businesses handle inbound and outbound calls, providing a
                  seamless and efficient communication solution. Utilizing
                  advanced AI technologies, our system not only automates call
                  handling but also enhances customer interactions through
                  personalized responses and data-driven insights. Whether
                  you're reaching out to potential clients or managing incoming
                  inquiries, our solution ensures that every call is handled
                  with precision and care, maximizing your sales potential and
                  customer satisfaction.
                </p>
                <h6>Key Features:</h6>
                <p className="mb-0">
                  <i
                    class="fa fa-check-circle  me-3"
                    style={{
                      color: "var(--primary-color)",
                    }}
                  ></i>
                  <span
                    style={
                      {
                        // fontWeight: "500",
                      }
                    }
                  >
                    Automated Call Handling
                  </span>{" "}
                  {/* Automatically manages and directs calls, reducing wait times
                  and improving customer experience. */}
                </p>
                <p className="mb-0">
                  <i
                    class="fa fa-check-circle  me-3"
                    style={{
                      color: "var(--primary-color)",
                    }}
                  ></i>
                  <span
                  // style={{
                  //   fontWeight: "500",
                  // }}
                  >
                    Personalized Interactions
                  </span>{" "}
                  {/* Delivers tailored responses based on customer data and
                  interaction history, making every call feel unique and
                  personal. */}
                </p>
                <p class="mb-0">
                  <i
                    class="fa fa-check-circle me-3"
                    style={{
                      color: "var(--primary-color)",
                    }}
                  ></i>
                  <span
                  // style={{
                  //   fontWeight: "500",
                  // }}
                  >
                    Advanced Speech Recognition
                  </span>
                  {/* Accurately understands and processes spoken language, ensuring
                  clear and effective communication. */}
                </p>
                <p class="mb-0">
                  <i
                    class="fa fa-check-circle me-3"
                    style={{
                      color: "var(--primary-color)",
                    }}
                  ></i>
                  <span
                  // style={{
                  //   fontWeight: "500",
                  // }}
                  >
                    Data-Driven Insights
                  </span>
                  {/* Provides detailed analytics and reports on call performance,
                  helping you make informed business decisions. */}
                </p>
                <p class="mb-0">
                  <i
                    class="fa fa-check-circle me-3"
                    style={{
                      color: "var(--primary-color)",
                    }}
                  ></i>
                  <span
                  // style={{
                  //   fontWeight: "500",
                  // }}
                  >
                    Scalable Solutions
                  </span>
                  {/* Easily scales to accommodate growing call volumes, ensuring
                  your business can handle increased demand without compromising
                  quality. */}
                </p>
                <p class="mb-0">
                  <i
                    class="fa fa-check-circle me-3"
                    style={{
                      color: "var(--primary-color)",
                    }}
                  ></i>
                  <span
                  // style={{
                  //   fontWeight: "500",
                  // }}
                  >
                    CRM Integration
                  </span>
                  {/* Seamlessly integrates with popular CRM systems, allowing for
                  smooth data synchronization and improved workflow efficiency. */}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="container-xxl py-6 pb-4">
          <div className="text-center d">
            <h1
              class="mb-10"
              style={{
                color: "var(--primary-color)",
                fontSize: "40px",
                fontWeight: "700",
                padding: "2rem 0rem",
              }}
            >
              Our Generative AI Services
            </h1>
          </div>
          <div className="container">
            <div className="row g-5">
              <div
                className="col-lg-6 wow fadeInUp"
                data-wow-delay="0.1s"
                style={{
                  marginTop: "3rem",
                }}
              >
                <img
                  className="spark-flow-img-fluid-rounded1"
                  src={chatwithus}
                  alt="AI Img"
                />
              </div>
              <div
                className="col-lg-6 wow fadeInUp generative-ai-for-enterprises"
                data-wow-delay="0.5s"
              >
                <h1 className="mb-4">Generative AI for Enterprises</h1>
                <p className="mb-4">
                  We offer a variety of generative AI solutions that can be
                  utilized to automate tasks, enhance customer experience, and
                  generate ideas. Our solutions include.
                </p>
                <ul
                  className="process mb-0"
                  style={{
                    listStyle: "none",
                  }}
                >
                  <li>
                    <div>
                      <h5>
                        {" "}
                        <span>
                          <i class="fas fa-database"></i>
                        </span>{" "}
                        LLM for Business Datasets
                      </h5>
                      <p>
                        Leverage advanced language models tailored for business
                        datasets to extract valuable insights, enhance
                        decision-making, and optimize operations.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div>
                      <h5>
                        {" "}
                        <span>
                          <i className="bi bi-chat-dots"></i>
                        </span>{" "}
                        Chatbots and Virtual Assistants
                      </h5>
                      <p>
                        Empower your customer interactions and streamline
                        support services with intelligent chatbots and virtual
                        assistants, providing personalized assistance round the
                        clock.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div>
                      <h5>
                        {" "}
                        <span>
                          <i className="bi bi-graph-up"></i>
                        </span>{" "}
                        Data Augmentation for Service-Based Apps
                      </h5>
                      <p>
                        Enhance the effectiveness of your service-based
                        application by expanding your dataset through artificial
                        methods, improving the app's performance and
                        adaptability.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div>
                      <h5>
                        {" "}
                        <span>
                          <i className="bi bi-heart"></i>
                        </span>{" "}
                        Personalized Recommendations
                      </h5>
                      <p>
                        Offer customized suggestions to users by using smart
                        algorithms that analyze their preferences and behaviors,
                        ensuring relevant and enjoyable recommendations.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="container-xxl py-6">
          <div className="container">
            <div className="row ">
              <div
                className="col-lg-6 order-lg-2 wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <img
                  className="spark-flow-img-fluid-rounded2"
                  src={saasbased}
                  alt="AI Img"
                />
              </div>
              <div
                className="col-lg-6 order-lg-1 ai-saas-based-application wow fadeInUp 7"
                data-wow-delay="0.5s"
              >
                <h1 className="mb-4">AI SaaS Based Application</h1>
                <p className="mb-4">
                  We specialize in providing services for AI SaaS-based
                  applications, empowering businesses to leverage advanced
                  technology for streamlined operations and enhanced efficiency.
                  Our tailored solutions ensure seamless integration and
                  scalability to meet the unique needs of each client,
                  delivering personalized user experiences and valuable
                  insights. With our expertise, businesses can stay ahead in the
                  competitive landscape, harnessing the full potential of AI for
                  sustainable growth.
                </p>
                <ul
                  className="process mb-0"
                  style={{
                    listStyle: "none",
                  }}
                >
                  <li>
                    <div>
                      <h5>
                        <span>
                          <i className="bi bi-briefcase"></i>
                        </span>{" "}
                        AI-Enhanced Sales and CRM Software
                      </h5>
                      <p>
                        Improve sales processes and customer relationship
                        management with AI-powered tools that optimize lead
                        generation, conversion, and retention.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div>
                      <h5>
                        {" "}
                        <span>
                          <i className="bi bi-image"></i>
                        </span>{" "}
                        Image Recognition and Analysis Solutions
                      </h5>
                      <p>
                        Identify objects, patterns, and anomalies in images with
                        AI-enabled software, enabling automated analysis and
                        decision-making.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div>
                      <h5>
                        {" "}
                        <span>
                          <i className="bi bi-graph-up"></i>
                        </span>{" "}
                        AI-Powered Marketing Automation Tools
                      </h5>
                      <p>
                        Optimize marketing campaigns with AI-driven tools that
                        analyze data, predict trends, and personalize content
                        for targeted audiences.{" "}
                      </p>
                    </div>
                  </li>

                  <li>
                    <div>
                      <h5>
                        {" "}
                        <span>
                          <i className="bi bi-lightning"></i>
                        </span>{" "}
                        Intelligent Process Automation Software
                      </h5>
                      <p>
                        Automate repetitive tasks and streamline workflows with
                        AI-powered software, improving operational efficiency
                        and productivity.{" "}
                      </p>
                    </div>
                    <li>
                      <div>
                        <h5>
                          {" "}
                          <span>
                            <i className="bi bi-people"></i>
                          </span>{" "}
                          AI-Powered Customer Support Solutions
                        </h5>
                        <p>
                          Enhance customer satisfaction and streamline support
                          operations with AI-driven chatbots and virtual
                          assistants.{" "}
                        </p>
                      </div>
                    </li>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <AdvanceFeatures />
        <div
          className="container-xxl pt-14"
          id="features"
          style={{
            marginTop: "2rem",
          }}
        >
          <div class="container">
            <div
              class="mx-auto text-center wow fadeInUp"
              data-wow-delay="0.1s"
              style={{
                maxWidth: "600px",
              }}
            >
              <h1
                class="mb-3"
                style={{
                  color: "var(--primary-color)",
                  fontSize: "40px",
                  fontWeight: "700",
                }}
              >
                Advanced Features
              </h1>
              <p class="mb-5">
                Unlock the future of software innovation with our cutting-edge
                advanced features, meticulously crafted to transform both AI and
                SaaS-based applications, propelling your business into a realm
                of unparalleled success and efficiency.
              </p>
            </div>

            <div class="row g-4">
              <div class="col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <div class="advanced-feature-item text-center rounded py-5 px-4">
                  <i
                    class="fa  bi-lightbulb fa-3x  mb-4"
                    style={{
                      color: "var(--primary-color)",
                    }}
                  ></i>
                  <h5 class="mb-3">AI-Driven Chatbot Learning</h5>
                  <p class="m-0">
                    Empower your chatbots with dynamic learning capabilities.
                    Our AI chatbots are equipped with sophisticated machine
                    learning technologies that adapt and evolve based on user
                    interactions. This continuous learning process ensures your
                    chatbots deliver smarter, more context-aware responses,
                    leading to improved user satisfaction and engagement.
                  </p>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                <div class="advanced-feature-item text-center rounded py-5 px-4">
                  <i
                    class="fa  bi-graph-up fa-3x  mb-4"
                    style={{
                      color: "var(--primary-color)",
                    }}
                  ></i>
                  <h5 class="mb-3">Insightful SaaS Analytics</h5>
                  <p class="m-0">
                    Transform data into insights with our AI-powered analytics
                    dashboard. Designed for SaaS environments, this dashboard
                    utilizes machine learning to analyze usage patterns and
                    operational data, providing you with deep insights into
                    application performance and user engagement. Make informed
                    decisions and drive your business forward with data-driven
                    strategies.
                  </p>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                <div class="advanced-feature-item text-center rounded py-5 px-4">
                  <i
                    class="fa bi-people fa-3x  mb-4"
                    style={{
                      color: "var(--primary-color)",
                    }}
                  ></i>
                  <h5 class="mb-3">Collaborative AI Ecosystems</h5>
                  <p class="m-0">
                    {" "}
                    Streamline collaboration with AI-enhanced tools. Our SaaS
                    solutions incorporate real-time collaborative features,
                    enabling teams to work efficiently on shared projects from
                    anywhere in the world. AI integration facilitates seamless
                    interactions and automates routine tasks, boosting
                    productivity and team synergy.
                  </p>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="0.7s">
                <div class="advanced-feature-item text-center rounded py-5 px-4">
                  <i
                    class="fa bi-chat-dots fa-3x  mb-4"
                    style={{
                      color: "var(--primary-color)",
                    }}
                  ></i>
                  <h5 class="mb-3">Conversational AI Enhancements</h5>
                  <p class="m-0">
                    Enhance user interactions with Natural Language Processing
                    (NLP). Our AI applications leverage NLP to understand and
                    interpret human language with remarkable accuracy. This
                    capability allows for more natural and engaging
                    conversations with users, enhancing the overall user
                    experience and fostering stronger connections.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <OurClients />
        <SparkAccordian />
      </div>
      <Footer />
    </div>
  );
};

export default AiChatbot;
