import React, { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "bootstrap/dist/css/bootstrap.min.css";
import Routes from "../src/pages/Routes";
import AppContext from "./context/AppContext";
// import Chatboot from "./pages/components/chatboot/Chabot.jsx";
import "./pages/components/Footer/Footer.css";
// import { RxCross2 } from "react-icons/rx";
// import chatboot from "./assets/Images/chatboticon.png";
export default function App() {
  // const [isOpen, setIsOpen] = React.useState(false);
  // const toggleChat = () => {
  //   setIsOpen((prevIsOpen) => !prevIsOpen);
  // };
  useEffect(() => {
    require("bootstrap/dist/js/bootstrap.bundle.min.js");
  }, []);

  return (
    <div>
      <AppContext>
        <Routes />
        {/* <Chatboot isOpen={isOpen} setIsOpen={setIsOpen} /> */}
        {/* <div>
          <div>
            <div onClick={toggleChat}>
              {!isOpen ? (
                <img className="spark-chatbot-image" src={chatboot} alt="" />
              ) : (
                <div className="spark-chatbot-image2">
                  <div
                    className={`sprak-cros-container ${isOpen ? "rotate" : ""}`}
                  >
                    <RxCross2
                      size={20}
                      style={{ color: "white", fontSize: "20px" }}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div> */}
        <ToastContainer />
      </AppContext>
    </div>
  );
}
