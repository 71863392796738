import React, { useState, useEffect } from "react";
import "./AboutUs.css";
import Navbar from "../../components/Navbar/Navbar";
import img1 from "../../../assets/gifs/thinking.gif";
import Industries from "../../components/Industries/Industries";
import Footer from "../../components/Footer/Footer";
import { useStateContext } from "../../../context/AppContext";
import AIChatButton from "../../components/AIChatButton/AIChatButton";
export default function AboutUs() {
  const { dark } = useStateContext();
  const [isCardVisible1, setIsCardVisible1] = useState(false);
  const [isCardVisible2, setIsCardVisible2] = useState(false);
  const [isCardVisible3, setIsCardVisible3] = useState(false);
  const [isCardVisible4, setIsCardVisible4] = useState(false);
  const [dividerHeight, setDividerHeight] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const cardElement1 = document.querySelector(".au-card1");
      const cardElement2 = document.querySelector(".au-card2");
      const cardElement3 = document.querySelector(".au-card3");
      const cardElement4 = document.querySelector(".au-card4");

      if (cardElement1) setIsCardVisible1(isElementVisible(cardElement1));
      if (cardElement2) setIsCardVisible2(isElementVisible(cardElement2));
      if (cardElement3) setIsCardVisible3(isElementVisible(cardElement3));
      if (cardElement4) setIsCardVisible4(isElementVisible(cardElement4));
    };

    const isElementVisible = (element) => {
      const rect = element.getBoundingClientRect();
      return rect.top <= window.innerHeight * 0.5 && rect.bottom >= 0;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (
      !isCardVisible1 &&
      !isCardVisible2 &&
      !isCardVisible3 &&
      !isCardVisible4
    ) {
      setDividerHeight(0);
    }
    if (
      isCardVisible1 &&
      !isCardVisible2 &&
      !isCardVisible3 &&
      !isCardVisible4
    ) {
      setDividerHeight(24);
    }
    if (
      isCardVisible1 &&
      isCardVisible2 &&
      !isCardVisible3 &&
      !isCardVisible4
    ) {
      setDividerHeight(49);
    }
    if (isCardVisible1 && isCardVisible2 && isCardVisible3 && !isCardVisible4) {
      setDividerHeight(76);
    }
    if (isCardVisible1 && isCardVisible2 && isCardVisible3 && isCardVisible4) {
      setDividerHeight(76);
    }
    if (
      !isCardVisible1 &&
      !isCardVisible2 &&
      isCardVisible3 &&
      isCardVisible4
    ) {
      setDividerHeight(76);
    }
    if (
      !isCardVisible1 &&
      !isCardVisible2 &&
      !isCardVisible3 &&
      isCardVisible4
    ) {
      setDividerHeight(76);
    }
  }, [isCardVisible1, isCardVisible2, isCardVisible3, isCardVisible4]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Navbar />
      <div className="aboutUs-main">
        <div className="au-www">
          <h1>
            <span style={{ color: dark }}>WHO WE</span> ARE
          </h1>
          <p>
            Introducing Sparkix Technologies, a distinguished provider of
            software development services and IT consultancy, dedicated to
            crafting bespoke digital solutions tailored precisely to your
            business requirements. Comprising a conscientious team of
            innovators, we are committed to distilling the essence of your ideas
            and tirelessly pursuing best-in-class results. Our ethos is deeply
            rooted in innovation, fostering mutual success and the creation of
            sustainable technological products.
          </p>
          <p>
            Founded in 2023, we embarked on our journey as a proficient IT
            consulting and software development company. Our primary mission is
            to assist businesses in building future-ready software products
            while addressing the intricate challenges of the IT landscape. Over
            the years, we have undergone a transformative evolution into a
            dynamic software development firm, harnessing the latest
            technologies to conceive and construct cutting-edge digital
            products. Our team of top-tier experts is dedicated to elevating
            your digital aspirations into meaningful and scalable solutions.
          </p>
        </div>

        <div className="au-whyus">
          <div className="au-whyus-left">
            <h1>
              WHY <span style={{ color: dark }}>US?</span>
            </h1>
            <p>
              Why us? Because we deliver promising software solutions. We
              approach each project with dedication, unleashing our creativity
              and seamless expertise. Stackup Solutions knows how to pioneer the
              idea and where to offer support to the businesses. We provide
              full-scale digital products in the latest technologies and be a
              profound partner in your growth.
            </p>
          </div>
          <div className="au-whyus-right">
            <img src={img1} alt="Loading..." />
          </div>
        </div>

        <div className="au-ourcv">
          <h1>
            OUR <span style={{ color: dark }}>CORE VALUES</span>
          </h1>
          <p>We empower our clients with unmatched values:</p>

          <div className="container au-container">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12 col-12 col">
                <div className="au-ourcv-card au-card1">
                  <h6>Quality &amp; Continuous Improvement</h6>
                  <p>
                    Delivering high-quality products that surpass industry best
                    practices is something we never compromise on. Our
                    experienced professionals conduct meticulous tests at every
                    stage of development, ensuring that our solutions are
                    robust, secure, and seamless.
                  </p>
                  <div className="au-card-arrow-right"></div>
                </div>
                <div
                  className="au-card-circle-right shadow"
                  style={{
                    backgroundColor: isCardVisible1 ? dark : "white",
                  }}
                ></div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 col-12 col"></div>
            </div>

            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12 col-12 col"></div>
              <div className="col-lg-6 col-md-12 col-sm-12 col-12 col">
                <div className="au-ourcv-card au-card2">
                  <h6>Innovative Tech Stacks</h6>
                  <p>
                    We like exploring cutting-edge technologies and making use
                    of their potential to build future-ready solutions. Our team
                    constantly excels in usability, scalability, and speed with
                    cost-effective software solutions
                  </p>
                  <div className="au-card-arrow-left"></div>
                </div>
                <div
                  className="au-card-circle-left shadow"
                  style={{
                    backgroundColor: isCardVisible2 ? dark : "white",
                  }}
                ></div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12 col-12 col">
                <div className="au-ourcv-card au-card3">
                  <h6>Commitment to Security and Data Privacy</h6>
                  <p>
                    We are mindful of how crucial data security and privacy are
                    in today's digital world. Our commitment to security extends
                    throughout the development process, from architecture design
                    to deployment, providing you with peace of mind and security
                    of your valuable data.
                  </p>
                  <div className="au-card-arrow-right"></div>
                </div>
                <div
                  className="au-card-circle-right shadow"
                  style={{
                    backgroundColor: isCardVisible3 ? dark : "white",
                  }}
                ></div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 col-12 col"></div>
            </div>

            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12 col-12 col"></div>
              <div className="col-lg-6 col-md-12 col-sm-12 col-12 col">
                <div className="au-ourcv-card au-card4">
                  <h6>Value-Driven Solutions</h6>
                  <p>
                    We provide smart solutions to suit your needs based on your
                    specifications. We offer solutions with user-friendly,
                    multi-platform compatibility, cutting-edge security
                    features, and maintenance and support services. We ensure
                    value-driven results.{" "}
                  </p>
                  <div className="au-card-arrow-left"></div>
                </div>
                <div
                  className="au-card-circle-left shadow"
                  style={{
                    backgroundColor: isCardVisible4 ? dark : "white",
                  }}
                ></div>
              </div>
            </div>

            <div className="au-divider"></div>
            <div
              className="au-divider2"
              style={{
                height: `${dividerHeight}%`,
                backgroundColor: dark,
              }}
            ></div>
          </div>
        </div>

        <div className="au-industries">
          <Industries />{" "}
        </div>

        <div className="mt-5 mb-5 d-flex justify-content-center">
          <AIChatButton />
        </div>
      </div>
      <Footer />
    </>
  );
}
